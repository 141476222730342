import React, { Component } from "react";
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { Container, Row, Col } from "reactstrap";
import * as auth from "./auth.js";
import { resetClient } from "modules/gql-client";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const appSpinner = (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col>
          <div className="sk-double-bounce">
            <div className="sk-child sk-double-bounce1"></div>
            <div className="sk-child sk-double-bounce2"></div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const AdminLayout = React.lazy(() => import("./admin/containers/AdminLayout"));
const Login = React.lazy(() => import("./views/Pages/Login"));
const Logout = React.lazy(() => import("./views/Pages/Logout"));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const SignUp = React.lazy(() => import("./views/Pages/SignUp"));
const Transfeed = React.lazy(() => import("./views/Pages/Transfeed"));
const Page403 = React.lazy(() => import("./views/Pages/Page403"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const Newoffer = React.lazy(() => import("./views/Newoffer"));
const W1Offer = React.lazy(() => import("./views/W1Offer"));

function WaiterRoute({
  renderComponent,
  loggedUserInfo,
  stateChanged,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const userInfo = loggedUserInfo();
        if (!stateChanged() || !userInfo) return appSpinner;
        let role = loggedUserInfo().role || "guest";
        if (role === "guest") return <Redirect to="/login" />;

        let hasWaiterAccount = loggedUserInfo().waiter_id || false;
        if (hasWaiterAccount) {
          if (loggedUserInfo().defaultPage && props.location.pathname === "/") {
            return <Redirect to={loggedUserInfo().defaultPage} />;
          }
          if (
            loggedUserInfo().offer !== null &&
            props.location.pathname !== "/newoffer" &&
            loggedUserInfo().su === null
          ) {
            return <Redirect to="/newoffer" />;
          }
          return renderComponent(props);
        }
        if (role === "admin") return <Redirect to="/admin" />;

        return <Redirect to="/500" />;
      }}
    />
  );
}

function AdminRoute({
  renderComponent,
  loggedUserInfo,
  stateChanged,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const userInfo = loggedUserInfo();
        if (!stateChanged() || !userInfo) return appSpinner;
        let role = loggedUserInfo().role || "guest";
        if (role === "guest") return <Redirect to="/login" />;
        if (role === "admin") return renderComponent(props);
        // then it is waiter in SU mode
        return <Redirect to="/" />;
      }}
    />
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      loggedUserInfo: false,
      user: {},
      stateChanged: false,
      alertIsVisible: false,
      tmpAlertModalIsVisible: false,
    };
    this.adminRouteProps = {
      name: "Admin",
      loggedUserInfo: () => this.state.loggedUserInfo,
      stateChanged: () => this.state.stateChanged,

      renderComponent: (props) => (
        <AdminLayout
          {...props}
          auth={auth}
          handleLogout={this.handleLogout}
          handleLogin={this.handleLogin}
          userInfo={this.userInfo}
        />
      ),
    };
    this.waiterRouteProps = {
      name: "Waiter",
      // isLoggedIn: () => this.state.isLoggedIn,
      loggedUserInfo: () => this.state.loggedUserInfo,
      stateChanged: () => this.state.stateChanged,
      userInfo: this.userInfo,
      renderComponent: (props) => (
        <DefaultLayout
          {...props}
          auth={auth}
          handleLogout={this.handleLogout}
          userInfo={this.userInfo}
          alertIsVisible={() => this.state.alertIsVisible}
          setAlertState={this.setAlertState}
          tmpAlertModalIsVisible={() => this.state.tmpAlertModalIsVisible}
          setTmpAlertModalState={this.setTmpAlertModalState}
        />
      ),
    };
    // this.registerRouteProps = { render:props => <Register {...props}/>,
    //   name:"Register Page"
    // }
    this.signupRouteProps = {
      render: (props) => <SignUp {...props} />,
      name: "SignUp Page",
    };
    this.loginRouteProps = {
      render: (props) => <Login {...props} handleLogin={this.handleLogin} />,
      name: "Login Page",
    };
    this.logoutRouteProps = {
      render: (props) => (
        <Logout
          {...props}
          handleLogout={this.handleLogout}
          loggedIn={() => this.state.isLoggedIn}
        />
      ),
      name: "Logout Page",
    };
    this.transfeedRouteProps = {
      render: (props) => <Transfeed {...props} />,
      name: "Transactions Feed",
    };
    this.newofferRouteProps = {
      name: "New offer",
      loggedUserInfo: () => this.state.loggedUserInfo,
      stateChanged: () => this.state.stateChanged,
      userInfo: this.userInfo,
      renderComponent: (props) => (
        <Newoffer
          {...props}
          auth={auth}
          updateLoggedUserInfo={this.updateLoggedUserInfo}
          handleLogout={this.handleLogout}
          userInfo={this.userInfo}
          alertIsVisible={() => this.state.alertIsVisible}
          setAlertState={this.setAlertState}
        />
      ),
    };
    this.w1OfferRouteProps = {
      name: "W1 offer",
      loggedUserInfo: () => this.state.loggedUserInfo,
      stateChanged: () => this.state.stateChanged,
      userInfo: this.userInfo,
      renderComponent: (props) => (
        <W1Offer
          {...props}
          userInfo={this.userInfo}
          auth={auth}
          updateLoggedUserInfo={this.updateLoggedUserInfo}
        />
      ),
    };
  }

  userInfo = () => this.state.loggedUserInfo;

  setAlertState = (alertIsVisible) => this.setState({ alertIsVisible });

  setTmpAlertModalState = (tmpAlertModalIsVisible) => this.setState({ tmpAlertModalIsVisible });

  handleLogout = async (user) => {
    resetClient(); // Clear urql cache
    if (user.id === "0") {
      this.setState({
        isLoggedIn: false,
        loggedUserInfo: { id: "0", role: "guest" },
      });
    } else {
      // it is exit from su
      this.setState({ isLoggedIn: true, loggedUserInfo: user });
      if (user.role === "admin") {
        // window.location.href = "/admin/clients"
        return "/admin/clients";
      } else {
        // window.location.href = "/"
        return "/";
      }
    }
  };

  handleLogin = async (loggedUserInfo) => {
    let isLoggedIn = false;
    if (
      loggedUserInfo &&
      loggedUserInfo.role &&
      loggedUserInfo.role !== "guest"
    ) {
      isLoggedIn = true;
    }
    this.setState({
      isLoggedIn,
      loggedUserInfo,
      stateChanged: true,
      alertIsVisible: loggedUserInfo.alert ? true : false,
      tmpAlertModalIsVisible: loggedUserInfo.tmpAlert ? true : false,
    });
  };

  componentDidMount() {
    auth
      .loggedUserInfo()
      .then((loggedUserInfo) => this.handleLogin(loggedUserInfo))
      .catch((error) => {
        console.error({ error });
      });
  }

  updateLoggedUserInfo = () =>
    auth
      .loggedUserInfo()
      .then((loggedUserInfo) => this.handleLogin(loggedUserInfo))
      .catch((error) => {
        console.error({ error });
      });

  render() {
    // console.log(`App page render(): %c${this.state.isLoggedIn ?
    // "Logged In": "NOT Logged In"} `,`color:${this.state.isLoggedIn?"green":
    // "red"}`);
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route exact path="/register" {...this.registerRouteProps}/> */}
            <Route exact path="/register" {...this.signupRouteProps} />
            <Route exact path="/policy" {...this.signupRouteProps} />
            <Route exact path="/offer" {...this.signupRouteProps} />
            <Route exact path="/login" {...this.loginRouteProps} />
            <Route exact path="/logout" {...this.logoutRouteProps} />
            <Route exact path="/transfeed" {...this.transfeedRouteProps} />
            <Route
              exact
              path="/403"
              name="Page 403"
              render={(props) => <Page403 {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route exact path="/500" name="Page 500">
              {" "}
              <Page500 />{" "}
            </Route>
            <AdminRoute
              {...this.adminRouteProps}
              exact
              path={[
                "/admin",
                "/admin/bot",
                "/admin/clients/prebalance/:waiterId",
                "/admin/clients-mobi",
                "/admin/clients",
                "/admin/dashboard",
                "/admin/dashboard/terminals",
                "/admin/monitoring/modul-kassa",
                "/admin/dashboard/tips",
                "/admin/dashboard/top",
                "/admin/dashboard/cities",
                "/admin/dashboard",
                "/admin/filters",
                "/admin/clients",
                "/admin/settings/broadcast",
                "/admin/settings/bot",
                "/admin/settings/offer",
                "/admin/settings",
                "/admin/transactions",
                "/admin/terminals",
                "/admin/terminal",
                "/admin/w1/bank-transactions",
                "/admin/w1/payout",
                "/admin/w1/revenue",
                "/admin/w1",
                "/admin",
              ]}
            />
            <AdminRoute {...this.adminRouteProps} path={["/admin/terminal"]} />
            <WaiterRoute
              {...this.waiterRouteProps}
              exact
              path={[
                "/update",
                "/edit",
                "/money",
                "/money-soap",
                "/soapmoney",
                "/paylog",
                "/feedback",
                "/profile",
                "/report",
                "/w1/money",
                "/w1/profile",
                "/w1/report",
                "/w1/my-terminals",
                "/",
              ]}
            />
            <WaiterRoute
              {...this.newofferRouteProps}
              exact
              path={["/newoffer"]}
            />
            <WaiterRoute
              {...this.w1OfferRouteProps}
              exact
              path={["/w1-offer"]}
            />
            {/* Rest queries routered to unknown page */}
            <Route
              path="/"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
